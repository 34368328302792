'use client'

import { useState, useEffect } from 'react'
import Image from '@/components/Image'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from '@material-tailwind/react'
import FakeLink from '@/components/FakeLink'
import WishlistButton from '@/components/WishlistButton'
import { usePage, useDictionary } from '@/providers'
import { usePathname } from 'next/navigation'
import Icon from '@/components/Icon'
import { Badge } from '@/types'
import { Badges } from '@/components/Badges'
import Similars from '@/components/ProductCard/Similars'
import Description from '@/components/ProductCard/Description'
import emitter, { DeleteFavoriteProductEvent } from '@/utils/events'
import {
  CurrentPageTrackingData,
  GetComponentTrackingSpecificData,
  GetExternalLinkTrackingProductParams,
  GetExternalLinkTrackingUrlParams,
  BuildHref,
  NG_WAS_OUTFIT_DETAIL_PRODUCT_SIMILARITY_BOX,
  TrackingExternalUrlSpecificData,
} from '@/components/NgTracker'

export type Props = {
  id: number
  href: string
  title: string
  generatedDescription: string
  price: string
  hasSale: boolean
  previousPrice: string
  shop: string
  parentUrl: string
  parentOfferCount: number
  mediaUrl: string
  mediaAlt: string
  mediaKey: string
  mediaBucket: string
  available: boolean
  slug?: string
  badges?: Array<Badge.Badge>
  showSimilar?: boolean
  shopId: number
  className?: string
  inLastSeen?: boolean
  favorite?: boolean
  imageProps?: object
  componentTrackingSpecificData?: TrackingExternalUrlSpecificData | undefined
}

export default function ProductCard({
  id,
  href,
  title,
  generatedDescription,
  price,
  hasSale,
  previousPrice,
  shop,
  parentUrl,
  parentOfferCount,
  mediaAlt,
  mediaUrl,
  mediaKey,
  mediaBucket,
  badges = [],
  slug = '',
  showSimilar = false,
  shopId,
  className = '',
  inLastSeen = false,
  favorite = false,
  imageProps = {},
  componentTrackingSpecificData = {},
}: Props) {
  const pathname = usePathname()
  const { pages } = usePage()
  const d = useDictionary('product')

  const [trackingParams, setTrackingParams] = useState('')

  const isOutfitDetailPage = pathname.startsWith(`/${pages.outfit}/`)
  const similarProductsTrackingData = GetComponentTrackingSpecificData(
    isOutfitDetailPage ? componentTrackingSpecificData.outfitId : '',
    isOutfitDetailPage ? NG_WAS_OUTFIT_DETAIL_PRODUCT_SIMILARITY_BOX : '',
    componentTrackingSpecificData?.channelShopUrlTags,
    componentTrackingSpecificData?.channelId
  )

  useEffect(() => {
    setTrackingParams(
      GetExternalLinkTrackingUrlParams(componentTrackingSpecificData, shopId)
    )
  }, [CurrentPageTrackingData])

  return (
    <Card
      color='transparent'
      shadow={false}
      className={`w-full max-w-[26rem] flex-col rounded-none ${className}`}
    >
      <CardHeader
        floated={false}
        shadow={false}
        color='transparent'
        className='m-0 rounded-none'
      >
        <FakeLink
          className={`block h-64`}
          href={BuildHref(
            href,
            trackingParams,
            GetExternalLinkTrackingProductParams(id, shopId)
          )}
          title={title}
          target='_blank'
        >
          <Image
            data-key={mediaKey}
            data-bucket={mediaBucket}
            data-id={id}
            className={`creative-img-box absolute inset-x-0 inset-y-0 size-full object-scale-down ${
              inLastSeen ? 'object-contain' : 'object-cover'
            } object-center`}
            src={mediaUrl}
            alt={mediaAlt}
            width={260}
            height={260}
            {...imageProps}
          />

          {!!(badges && badges?.length) && <Badges badges={badges} />}
        </FakeLink>
        <WishlistButton
          id={id}
          type='product'
          isChecked={favorite}
          onDelete={() => emitter.emit(DeleteFavoriteProductEvent, id)}
        />
      </CardHeader>
      <CardBody className='mt-2 flex grow flex-col p-0'>
        <Typography
          variant='h3'
          className='line-clamp-[2] overflow-hidden text-ellipsis p-2 pt-0 text-left text-md font-normal text-primary'
        >
          <span className='line-clamp-[2] leading-normal'>{title}</span>
        </Typography>

        {generatedDescription && (
          <Description description={generatedDescription} />
        )}

        <div className='flex flex-col justify-between gap-2 p-2 pt-0'>
          {!!slug && (
            <FakeLink
              href={`/${pages.product}/${slug}`}
              className='flex items-center gap-1.5 text-left text-xs font-normal text-secondary'
              style={{ float: 'left', marginTop: '1px' }}
            >
              <Icon icon='info' size='12px' style={{ marginTop: '-2px' }} />{' '}
              {d('details_link')}
            </FakeLink>
          )}

          <div className='flex items-center justify-between'>
            {shop && <span className='text-sm text-secondary'>{shop}</span>}
            <div className='flex flex-col gap-1 md:flex-row md:gap-2'>
              {hasSale && (
                <Typography
                  variant='lead'
                  className='text-left text-base font-light line-through opacity-90 md:text-right'
                >
                  {previousPrice}
                </Typography>
              )}
              <Typography
                variant='lead'
                className={`text-left text-base font-medium ${hasSale ? 'text-tertiary-red' : 'text-primary'} md:text-right`}
              >
                {price}
              </Typography>
            </div>
          </div>

          {!!parentUrl && (
            <a
              href={parentUrl}
              className='text-xs font-normal text-secondary'
              style={{
                float: 'right',
                padding: '5px 10px',
                border: '1px solid #999',
                color: '#333',
              }}
            >
              <Icon icon='tags' size='12px' /> {d('price_comparison_button')}{' '}
              {parentOfferCount} {d('price_comparison_offers_counter')}
            </a>
          )}
        </div>
      </CardBody>

      {showSimilar && (
        <CardFooter className='p-0 pb-1'>
          <Similars
            id={id}
            componentTrackingSpecificData={similarProductsTrackingData}
          />
        </CardFooter>
      )}
    </Card>
  )
}

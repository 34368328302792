'use client'

import { useEffect, useRef, useState } from 'react'
import { Typography, Button } from '@material-tailwind/react'
import { Product } from '@/types'
import { useDictionary } from '@/providers'
import ProductCard from '@/components/ProductCard'
import { fetchSimilarProducts } from '@/utils/actions/product'
import IconSvg from '@/components/IconSvg'
import { TrackingExternalUrlSpecificData } from '@/components/NgTracker'

type Props = {
  title: string
  maxCount: number
  productId: number
  products: Array<Product.Product>
  limit?: number
  className?: string
  infiniteScroll?: boolean
  componentTrackingSpecificData?: TrackingExternalUrlSpecificData | undefined
}

export default function RelatedProducts({
  title,
  maxCount,
  productId,
  products,
  limit = 4,
  className = '',
  infiniteScroll = false,
  componentTrackingSpecificData = {},
}: Props) {
  const d = useDictionary('related_products')

  const [productList, setProductList] = useState(products)
  const [loading, setLoading] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)

  const observerRef = useRef<IntersectionObserver | null>(null)
  const loadMoreRef = useRef<HTMLDivElement>(null)

  const onShowMore = async () => {
    setLoading(true)

    try {
      const { data } = await fetchSimilarProducts(
        productId,
        productList.length,
        limit
      )
      setProductList(productList.concat(data.products))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      /* empty */
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!infiniteScroll) return

    const handleScroll = () => {
      const currentScrollPos = window.scrollY
      const directionDown = currentScrollPos > scrollPosition
      setScrollPosition(currentScrollPos)
      return directionDown
    }

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      const isIntersecting = entries[0].isIntersecting
      const isScrollingDown = handleScroll()

      if (
        isIntersecting &&
        isScrollingDown &&
        productList.length < maxCount &&
        !loading
      ) {
        onShowMore()
      }
    }

    observerRef.current = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    })

    if (loadMoreRef.current) {
      observerRef.current.observe(loadMoreRef.current)
    }

    return () => {
      if (observerRef.current && loadMoreRef.current) {
        observerRef.current.unobserve(loadMoreRef.current)
      }
    }
  }, [infiniteScroll, productList, maxCount, loading, scrollPosition])

  return (
    <div className={`container ${className}`}>
      <div className='flex flex-col gap-4 py-8 md:flex-row md:items-center'>
        <Typography
          as='p'
          variant='h2'
          className='flex-1 text-left text-3xl font-semibold text-primary underline underline-offset-2'
        >
          {title}
        </Typography>
      </div>

      <div className='w-full'>
        <div className='grid grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4'>
          {productList.map((product, index) => (
            <ProductCard
              key={index}
              id={product.id}
              href={product.link}
              slug={product.slug}
              parentUrl={product.parentProduct?.url}
              parentOfferCount={product.parentProduct?.offerCount}
              mediaUrl={product.media?.imageUrl}
              mediaAlt={product.generatedDescription ?? product.name}
              mediaBucket={product.media?.bucket}
              mediaKey={product.media?.key}
              title={product.name}
              generatedDescription={product.generatedDescription}
              shop={product.shop}
              price={product.price}
              hasSale={product.hasSale}
              previousPrice={product.previousPrice}
              available={product.available}
              badges={product?.badges}
              className='max-w-full'
              favorite={product.favorite}
              shopId={product.shopId}
              componentTrackingSpecificData={componentTrackingSpecificData}
            />
          ))}
        </div>
        {!infiniteScroll && productList.length < maxCount && (
          <Button
            loading={loading}
            variant='outlined'
            className='mx-auto mt-6 block border border-secondary leading-[150%] text-primary'
            onClick={onShowMore}
          >
            {d('show_more_btn')}
          </Button>
        )}

        {infiniteScroll && loading && (
          <div className='mt-6 flex justify-center'>
            <IconSvg className='size-8 fill-black' icon='loading' />
          </div>
        )}

        {infiniteScroll && !loading && productList.length < maxCount && (
          <div ref={loadMoreRef} className='h-10' />
        )}
      </div>
    </div>
  )
}

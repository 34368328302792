import { Button, Typography } from '@material-tailwind/react'
import { useDictionary, usePage } from '@/providers'
import Image from '@/components/Image'
import { ProductVariants } from '@/types/Components/ParentProductDetail'
import { Badges } from '@/components/Badges'
import { useState } from 'react'
import FakeLink from '@/components/FakeLink'
import Link from '@/components/Link'

type Props = {
  variants: Array<ProductVariants>
  className?: string
}

export function ParentProductVariants({ variants, className = '' }: Props) {
  const d = useDictionary('parent_product_detail')
  const { pages } = usePage()

  const [visibleVariants, setVisibleVariants] = useState(12)

  if (!variants || !variants.length) {
    return null // Return null if there are no variants
  }

  const handleShowMore = () => {
    setVisibleVariants((prev) => prev + 8)
  }

  return (
    <div className={`container ${className} product-offer-gray-highlight`}>
      <div className='flex flex-col gap-4 py-8 md:flex-row md:items-center'>
        <Typography
          as='h2'
          variant='h2'
          className='flex-1 text-left text-3xl font-semibold text-primary underline-offset-2'
        >
          {d('variants_title')}
        </Typography>
      </div>

      <div className='w-full'>
        <div className='grid grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-4'>
          {variants.slice(0, visibleVariants).map((item, index) => {
            const CommonProps = {
              className:
                'block relative border border-light-gray-color bg-white p-2.5',
              href: `/${pages.parentProduct}/${item.parentProductSlug}/${item.nexgeonProductId}`,
              title: item?.title,
            }

            const Wrapper = item.indexable ? Link : FakeLink

            return (
              <Wrapper key={index} {...CommonProps}>
                <div className='relative mb-2 flex flex-col'>
                  <Image
                    className='h-[140px] w-full object-contain object-center'
                    src={item.media.imageUrl}
                    width={168}
                    height={140}
                    alt={item.media?.title || item.media?.bucket}
                    title={item.media?.title || item.media?.bucket}
                  />

                  {!!(item.badges && item.badges?.length) && (
                    <Badges badges={item.badges} />
                  )}
                </div>
                <div className='flex-1'>
                  <Typography
                    as='p'
                    variant='h5'
                    className='text-left text-3xl font-semibold text-primary'
                  >
                    <span className='text-lg'>{d('variant_prefix')}</span>{' '}
                    {item.minPrice}
                  </Typography>
                  <Typography
                    as='p'
                    variant='paragraph'
                    className='break-all text-left text-sm font-normal text-secondary'
                  >
                    {d('variants_item_product_count', {
                      count: `${item.productCount}`,
                    })}{' '}
                    {!!item.nexgeonProductName && item.nexgeonProductName}
                  </Typography>
                </div>
                {item.disabled && (
                  <div className='absolute bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center bg-white/80 p-2.5'>
                    {d('variants_disabled_text')}
                  </div>
                )}
              </Wrapper>
            )
          })}
        </div>
        {visibleVariants < variants.length && (
          <div className='mt-4 flex justify-center'>
            <Button
              onClick={handleShowMore}
              variant='outlined'
              className='border border-primary px-4 py-2 text-primary'
            >
              {d('show_more_offers')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
